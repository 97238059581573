<template>
  <div class="container p-5">
    <div v-html="$t('HtmlPrivacyPolice')">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            asSaloonName: process.env.VUE_APP_AS_NAME || 'AS Saloon',
            itexonName: process.env.VUE_APP_ITEXON_NAME || 'ITEXON SYSTEMS, S.L.',
            itexonCif: process.env.VUE_APP_ITEXON_CIF || 'B42793364',
            itexonSocialAddress: process.env.VUE_APP_ITEXON_SOCIAL_ADDRESS || 'Calle Loma de los Riscos 73, B2, 39. C.P. 29620, Torremolinos, Málaga. España',
            webUrl: process.env.VUE_APP_ITEXON_URL || 'https://itexon.com/',
            officeEmail: process.env.VUE_APP_OFFICE_EMAIL || 'office@itexon.com',
            rpgEmail: 'rgpd@itexon.com',
            numberPhone: process.env.VUE_APP_ITEXON_PHONE || '(+34) 951 331 789',
            notificationsAddressItexon: process.env.VUE_APP_ITEXON_NOTIFICATION_ADDRESS || 'Calle Barroso 14, 1º, CP: 29001-Málaga. España',
            agpdUrl: 'www.agpd.es'
        }
    },
    computed: { ...mapState(["defaultTitle"]) },
    metaInfo() {
        return {
            title: `${this.$t('footerLink.privacyPolicy')} - ${this.defaultTitle}`,
            meta: [
                {name: 'og:title', property: 'og:title', content:  `${this.$t('footerLink.privacyPolicy')} - ${this.defaultTitle}`},
                {name: 'og:type', property: 'og:type', content: 'website'},
                {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
                {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.privacyPolicy.description')},
                {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME},
                {name: 'description', content: this.$t('vueMeta.privacyPolicy.description')}
            ]
        }
    }
}
</script>

<style>
h1,h2,p{
    color: black;    
}
h1,h2,p{
    margin-top: 15px;
    margin-bottom: 15px;
}
.title {
    font-weight: bold;
    display: block;
}
li {
    margin-bottom: 1rem;
    font-size: 14px;
}
</style>